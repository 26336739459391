const config = {
  nav: [
    { href: "/", title: "Home" },
    { href: "/blog", title: "Blog" },
    { href: "/mentoring", title: "Mentoring" },
    { href: "/photography", title: "Photography" },
    { href: "/about", title: "About" },
  ],
  author: {
    name: "Luca Cavallin",
    avatar: "/images/luca_cavallin.webp",
    avatar_base64:
      "data:image/webp;base64,UklGRmwAAABXRUJQVlA4IGAAAABQAgCdASoKAAoAAgA0JZQCw7aXO+hhH+QBBOAA/vKTzbbUpr/eHmpz8ekbafkPj7Ef65+dCpZ//XE/qW28o2FRVHdy/aVLjXqNTVEgM9dkDl7PKxpA/mWAruWlxSQYAAA=",
    job: "Software Engineer, Mentor, Photographer.",
    company: "GitHub, Inc.",
    github: "https://github.com/lucavallin",
    linkedin: "https://www.linkedin.com/in/lucavallin",
    unsplash: "https://unsplash.com/@lucavallin",
    instagram: "https://instagram.com/lucavallincom",
    photography: "https://www.lucavallin.com",
    mentorcruise: "https://mentorcruise.com/mentor/lucacavallin/",
  },
  meta: {
    title: "Luca Cavallin - Software Engineering, Mentoring & Photography",
    title_short: "Luca Cavallin",
    headerTitle: "lucavallin",
    description: `
      Luca Cavallin, Software Engineer at GitHub. I build distributed systems and
      tooling for platform engineering and developer experience, improving how teams
      deploy and scale cloud-native workloads. I'm especially interested in Go,
      Kubernetes, networking, containers, and observability. I also maintain a strong
      interest in emerging technologies like Rust, eBPF, and WebAssembly for their
      potential to improve performance, security, and portability.

      Beyond my primary expertise, I've branched out into areas like frontend
      development with React and TypeScript, data engineering and Google Cloud to name
      a few. My past also saw me wearing the hats of a consultant, delivering hands-on
      training sessions, hosting podcasts, and organizing meetups for the tech
      community. I'm an active contributor to open source and believe in the
      importance of knowledge sharing, as evidenced by my blog posts and talks.
      Additionally, I hold the title of Google Developer Expert (GDE) and CNCF Ambassador.
      Outside of work, activities like photography, cycling, cooking on a kamado and cleaning litterboxes keep me occupied.
    `,
    photography_description:
      "I've been passionate about photography since I was a kid. I enjoy capturing a variety of subjects—landscapes, wildlife, macro shots, and sometimes industrial scenes. Whether it's the early morning light over a misty mountain, the tiny details of an insect, or the gritty look of factory chimneys, photography lets me explore and share how I see the world.",
    mentoring_description:
      "Looking to level up your software engineering career? With personalized mentorship on Mentorcruise, I'll help you crush your career goals, tackle software challenges, and achieve the success you're aiming for. Let's make your goals happen together!",
    blog_description:
      "Welcome to my blog, where I dive into all things Cloud Native, from Go and Kubernetes to containers and beyond. As a Senior Software Engineer at GitHub Actions, I build distributed systems and tooling for platform engineering and developer experience, and I also serve as a MentorCruise mentor - sharing insights on developer tools, career growth, and open source. Drop by for practical tips, tech stories, and hands-on support!",
    language: "en-us",
    theme: "system", // system, dark or light
    siteUrl: "https://www.lucavall.in",
    siteLogo: "/images/lucavallin.webp",
    socialBanner: "/images/meta.webp",
    locale: "en-US",
    search: {
      provider: "kbar",
      kbarConfig: {
        searchDocumentsPath: "search.json",
      },
    },
  },
  site: {
    blog_posts_per_page: 5,
  },
};

export default config;
